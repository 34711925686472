import { createClient } from '@libsql/client'
import { drizzle } from 'drizzle-orm/libsql'
import * as schema from './schema'

const client = createClient({
  url: process.env.TURSO_CONNECTION_URL,
  authToken: process.env.TURSO_AUTH_TOKEN,
})

export const db = drizzle(client, { schema })

export type DatabaseConnection = typeof db

export function makeDatabaseConnection({
  url,
  authToken,
}: { url: string; authToken: string }) {
  return drizzle(createClient({ url, authToken }), { schema })
}
